<template>
    <div class="app">
        <header>
			<a @click.prevent="$router.go(-1)" href="" class="back"><i class="fad fa-angle-left"></i></a>

			<p class="title">Faktur</p>
		</header>

        <div class="container pt-4">
            <section class="section1">
                <h6 class="mb-4">No. Order: ORD-{{ id }}</h6>

                <div class="row">
                    <div class="col-lg-6">
                        <p class="title mb-3">Subtotal</p>
                        <h6>Rp. {{ Number(data.subtotal).toLocaleString() }}</h6>
                    </div>

                    <div class="col-lg-6">
                        <p class="title mb-3">Payment Date</p>
                        <h6>
                            <div v-if="data.payment_date">{{ data.payment_date }}</div>
                            <div v-if="!data.payment_date">-</div>
                        </h6>
                    </div>
                </div>
            </section>

            <hr>

            <section class="section2">
                <div class="row">
                    <div class="col-lg-6">
                        <p class="title mb-2">Ship Detail</p>
                        <p class="content" v-if="data.user">{{ data.user.name }}, {{ data.address }}, {{ data.user.phone }} - {{ data.ship }}</p>
                    </div>

                    <div class="col-lg-6">
                        <p class="title mb-2">Payment Proof</p>

                        <div v-if="!data.image_url">-</div>
                        <router-link :to="`/view-image/${data.id}`" v-if="data.image_url" class="proof">Show Proof</router-link>
                    </div>
                </div>
            </section>

            <hr>

            <section class="section3">
                <h6 class="mb-4">Order Detail</h6>

                <ul class="lists mb-4">
                    <li v-for="log in data.logs" :key="log.id" class="list mb-3">
                        <div>
                            <p>{{ log.product_name }}</p>
                            <p class="detail">{{ log.detail }}</p>
                        </div>
                        <div>
                            <p class="qty">x{{ log.quantity }}</p>
                            <p class="total">Rp{{ Number(log.subtotal).toLocaleString() }}</p>
                        </div>
                    </li>
                </ul>

                <h6 class="mb-4">Ongkir</h6>

                <ul class="lists">
                    <li class="list mb-3">
                        <div>
                            <p>{{ data.ship.toUpperCase() }}</p>
                            <p class="detail">{{ data.ship_service }}</p>
                        </div>
                        <div>
                            <!-- <p class="qty">x{{ log.quantity }}</p> -->
                            <p class="total">Rp{{ Number(data.shipping_cost).toLocaleString() }}</p>
                        </div>
                    </li>
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            token: localStorage.getItem('token'),
            id: this.$route.params.id,
            data: ''
        }
    },
    methods: {
        getData() {
            axios.get(`order/${this.id}?token=${this.token}`)
                .then(res => {
                    this.data = res.data;
                    console.log(res.data)
                })
                .catch(err => {
                    console.log(err.response.data);
                }); 
        }
    },
    created() {
        this.getData();
    },
}
</script>

<style scoped>

.button {
    width: 100%;
    padding: .3em 2em;
}

.lists {
    padding: 0;
    margin: 0;
}

.list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.list p {
    margin: 0;
}

.list .detail {
    color: gray;
    font-size: 12px;
    margin: 0;
}

.list .qty {
    color: gray;
    font-size: 14px;
    text-align: right;
}

.list .total {
    font-size: 13px;
}

header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.container p.title {
    text-align: left;
    font-size: 14px;
}

.container p.content {
    font-size: 12px;
}

a.proof {
    color: var(--primary);
    font-weight: bold;
    font-size: 12px;
}

h6 {
    font-weight: bold;
}

hr {
    background-color: gray;
}

</style>